/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

//
export const getCategoriesForFilter = createAsyncThunk(
  'filter/getCategoriesForFilter',
  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/categories', {
        params: { type: 'listings', parent: 0 },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const getAccountCategoriesForFilter = createAsyncThunk(
  'filter/getAccountCategoriesForFilter',
  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/categories', {
        params: { type: 'accounts', parent: 0 },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const getAttributesForFilter = createAsyncThunk(
  'filter/getAttributesForFilter',
  async ({ ...prams }, thunkAPI) => {
    try {
      const response = await axios.get('/api/attributes', {
        params: { type: 'listings', ...prams },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const getCitiesForFilter = createAsyncThunk(
  'filter/getCitiesForFilter',
  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/l/locations', {
        params: { type: 'city', page: 1 },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const getCountriesForFilter = createAsyncThunk(
  'filter/getCountriesForFilter',
  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/l/locations', {
        params: { type: 'country', page: 1 },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// get quires
const get_quires = (listings_filter) => {
  const queries = {};
  for (const key in listings_filter) {
    if (key === 'category_ids' && listings_filter[key] !== null) {
      if (listings_filter[key]?.length === 0) {
        delete queries[key];
      } else {
        queries['category_id'] = listings_filter[key].join(',');
      }
    }
    if (key === 'attribute_value_ids' && listings_filter[key] !== null) {
      if (listings_filter[key]?.length === 0) {
        delete queries[key];
      } else {
        queries['attribute_value_id'] = listings_filter[key].join(',');
      }
    }
    if (key == 'sort' && listings_filter[key] !== null) {
      queries[key] = listings_filter[key];
    }
    if (key === 'start_at' && listings_filter[key] !== null) {
      queries[key] = `${moment(listings_filter[key]).format(
        'YYYY-MM-DD'
      )}T00:00:00Z`;
      queries['end_at'] = `${moment(listings_filter[key])
        .add(1, 'days')
        .format('YYYY-MM-DD')}T23:59:59Z`;
    }
    if (key === 'flexible_date_range' && listings_filter[key] !== null) {
      queries[key] = listings_filter[key];
    }
    if (
      key === 'max_distance' &&
      listings_filter[key] !== null &&
      listings_filter['latitude'] &&
      listings_filter['longitude']
    ) {
      queries[key] = listings_filter[key];
      queries['latitude'] = listings_filter['latitude'];
      queries['longitude'] = listings_filter['longitude'];
    }
  }

  return queries;
};

// get_params
const get_params = (queries) => {
  const params = {};

  for (const key in queries) {
    if (key === 'category_id') {
      const categoryIds = queries[key]?.split(',');
      if (categoryIds.length === 0) {
        params['category_ids'] = null;
      } else {
        params['category_ids'] = categoryIds;
      }
    }
    if (key === 'attribute_value_id') {
      const attributeValueIds = queries[key]?.split(',');
      if (attributeValueIds.length === 0) {
        params['attribute_value_ids'] = null;
      } else {
        params['attribute_value_ids'] = attributeValueIds.map((id) =>
          Number(id)
        );
      }
    }
    if (key === 'start_at' && queries[key] && queries[key] !== null) {
      const startDate = moment(queries[key], 'YYYY-MM-DDTHH:mm:ssZ').toDate();
      params[key] = startDate;
    }
    if (
      key === 'flexible_date_range' &&
      queries[key] &&
      queries[key] !== null
    ) {
      params[key] = queries[key];
    }
    if (key === 'sort' && queries[key] && queries['end_at']) {
      params[key] = queries[key];
    }
    if (
      key === 'max_distance' &&
      queries[key] &&
      queries['latitude'] &&
      queries['longitude']
    ) {
      params[key] = queries[key];
      params['latitude'] = queries['latitude'];
      params['longitude'] = queries['longitude'];
    } else if (queries[key] !== '') {
      params[key] = queries[key];
    }
  }

  return params;
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    categories: null,
    account_categories: null,
    attributes: null,
    cities: null,
    countries: null,
    filter_params: null,
    queries: {},
  },
  reducers: {
    clearErrorState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },
    onFilterParamsUpdate: (state, { payload }) => {
      const { callOnFilterUpdate, ...payload_data } = payload;

      // for sorting null or empty values
      let updated_payload_data = {};
      const updated_params = {
        ...state.filter_params,
        ...payload_data,
      };

      for (const key in updated_params) {
        if (updated_params[key] !== null && updated_params[key]?.length !== 0) {
          updated_payload_data = {
            ...updated_payload_data,
            [key]: updated_params[key],
          };
        }
      }
      //

      const queries = get_quires(updated_payload_data);
      state.filter_params = updated_payload_data;
      state.queries = queries;

      if (callOnFilterUpdate) {
        callOnFilterUpdate(queries);
      }
    },
    syncParamsWithQueries: (state, { payload }) => {
      const params = get_params(payload);
      state.filter_params = params;
      state.queries = payload;
    },
    clearFilterParams: (state) => {
      state.filter_params = null;
    },
  },
  extraReducers: {
    //   getCategoriesForFilter
    [getCategoriesForFilter.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.categories = payload?.categories;
      }
    },
    [getCategoriesForFilter.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getCategoriesForFilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.categories = null;
    },
    //   getAccountCategoriesForFilter
    [getAccountCategoriesForFilter.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.account_categories = payload?.categories;
      }
    },
    [getAccountCategoriesForFilter.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getAccountCategoriesForFilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.account_categories = null;
    },
    //   getAttributesForFilter
    [getAttributesForFilter.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.attributes = payload?.attributes;
      }
    },
    [getAttributesForFilter.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getAttributesForFilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.attributes = null;
    },
    //   getCitiesForFilter
    [getCitiesForFilter.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.cities = payload?.values;
      }
    },
    [getCitiesForFilter.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getCitiesForFilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.cities = null;
    },
    //   getCountriesForFilter
    [getCountriesForFilter.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.countries = payload?.values;
      }
    },
    [getCountriesForFilter.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
  },
});

export const {
  clearErrorState,
  onFilterParamsUpdate,
  clearFilterParams,
  onSearchByFilter,
  syncParamsWithQueries,
} = filterSlice.actions;
export const filterSelector = (state) => state.filter;
